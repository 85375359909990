import ajax from "plugins/AjaxService"
import { Incident, IncidentType } from "types/Incident"



/**
 * INDEX
 * https://app.clickup.com/30373227/v/dc/wyxbb-20075/wyxbb-51895
 */
async function index(farmId: number): Promise<{ data: Incident[] }> {
    return ajax.get(`farms/${farmId}/incidents`)
}

/**
 * GET
 * https://app.clickup.com/30373227/v/dc/wyxbb-20075/wyxbb-51935
 */
async function get(incidentId: number): Promise<{ data: Incident }> {
    return await ajax.get(`cycles/incidents/${incidentId}`)
}

/**
 * SAVE AND NEW
 * https://app.clickup.com/30373227/v/dc/wyxbb-20075/wyxbb-51975
 * https://app.clickup.com/30373227/v/dc/wyxbb-20075/wyxbb-51955
 */
async function save(incident: Incident): Promise<{ data: Incident }> {
    const data = exportDataFromIncident(incident)
    // è una modifica
    if (!!incident.id) {
        return ajax.patch(`cycles/incidents/${incident.id}`, data)
        // è uno nuovo
    } else {
        return ajax.post(`cycles/incidents`, data)
    }
}

/**
 * REVERT
 */
async function revert(incidentId: number): Promise<{ data: Incident }> {
    return ajax.patch(`incidents/${incidentId}`, { incident: { reverted: true } })
}

/**
 * PROCEEDED
 */
async function proceeded(incidentId: number): Promise<{ data: Incident }> {
    return ajax.patch(`incidents/${incidentId}`, { incident: { reverted: false } })
}

/**
 * DELETE
 * https://app.clickup.com/30373227/v/dc/wyxbb-20075/wyxbb-51995
 */
async function destroy(incidentId: number): Promise<void> {
    return ajax.delete(`cycles/incidents/${incidentId}`)
}

/**
 * INDEX TYPES
 * https://app.clickup.com/30373227/v/dc/wyxbb-20075/wyxbb-55875
 */
async function types(): Promise<{ data: IncidentType[] }> {
    return await ajax.get(`cycles/incidents/type`)
}

const incidentsApi = {
    index,
    get,
    save,
    destroy,
    proceeded,
    revert,
    types,
}
export default incidentsApi


/**
 * https://app.clickup.com/30373227/v/dc/wyxbb-20075/wyxbb-51955
 * usato in SAVE permette di formattare i dati in maniera da essere compatibili con il backend
 */
function exportDataFromIncident(incident: Partial<Incident>): any {
    return {
        incident: {
            incident_type: incident.incidentType,
            date_of_occurrence: incident.dateOfOccurrence,
            resolved: incident.resolved,
            reverted: incident.reverted,
            notes: incident.notes,


            
            position_of_trolleys: incident.positionOfTrolleys?.reduce((acc, trolley) => {
                acc[trolley.layerNumber] = trolley.positionOfTrolleys;
                return acc;
            }, {} as Record<number, number[]>),
            number_of_lost_trolleys_per_crop_lot: incident.lostTrolleysPerCropLot?.reduce((acc, cropLot) => {
                acc[cropLot.cropLotId] = cropLot.numberOfLostTrolleys;
                return acc;
            }, {} as Record<number, number>),
            crop_lots_ids: incident.lostTrolleysPerCropLot?.map(cropLot => cropLot.cropLotId),
            cycle_layer_numbers: incident.positionOfTrolleys?.map(trolley => trolley.layerNumber)
        }
    };
}