import { Box } from "@mui/material";
import { useStore } from "@priolo/jon";
import AlarmDetailFooter from "pages/alarms/cards/DetailFooter";
import AlarmTableFooter from "pages/alarms/list/TableFooter";
import CropDetailFooter from "pages/crops/detail/Footer";
import CropTableFooter from "pages/crops/list/Footer";
import PlannerFooter from "pages/farms/planner/footer/Footer";
import AlertsFooter from "pages/plc/main/subsystems/alert/Footer";
import ProductionsFooter from "pages/farms/productions/Footer";
import ProductDetailFooter from "pages/products/cards/DetailFooter";
import ProductTableFooter from "pages/products/list/TableFooter";
import RecipeDetailFooter from "pages/recipes/detail/DetailFooter";
import RecipeTableFooter from "pages/recipes/list/Footer";
import ChatField from "pages/root/ChatField";
import { FunctionComponent, useMemo } from "react";
import locationSo from "stores/route/location";
import { PAGE_ID } from "stores/route/utils/types";
import theme from "styles/theme";
import recipeDetailSo from "stores/library/recipe/detail";
import MetadataTableFooter from "pages/plc/metadata/TableFooter";



const PageFooter: FunctionComponent = () => {

	// STORE
	const locationSa = useStore(locationSo)
	useStore(recipeDetailSo)

	// HOOK
	const Buttons = useMemo(() => {
		return {
			[PAGE_ID.ALARMS]: <AlarmTableFooter />,
			[PAGE_ID.ALARMS_DETAIL]: <AlarmDetailFooter />,

			[PAGE_ID.CROPS]: <CropTableFooter />,
			[PAGE_ID.CROPS_DETAIL]: <CropDetailFooter />,

			[PAGE_ID.PRODUCTS]: <ProductTableFooter />,
			[PAGE_ID.PRODUCTS_DETAIL]: <ProductDetailFooter />,

			[PAGE_ID.RECIPES]: <RecipeTableFooter />,
			[PAGE_ID.RECIPES_DETAIL]: <RecipeDetailFooter />,
			[PAGE_ID.GROWUNIT_RECIPE]: <RecipeDetailFooter contained/>,

			[PAGE_ID.ROOT]: <ChatField sx={{ mx: "auto", minWidth: "700px", display: "flex" }}/>,

			[PAGE_ID.FARM_TASKS]: <PlannerFooter />,
			[PAGE_ID.FARM_CYCLES]: <PlannerFooter />,
			[PAGE_ID.FARM_AI_PLANNING]: <ProductionsFooter />,

			[PAGE_ID.FARM_PLC_ALERTS]: <AlertsFooter />,
			[PAGE_ID.GROWUNIT_PLC_ALERTS]: <AlertsFooter />,
			[PAGE_ID.COMPONENT_HVAC_ALERTS]: <AlertsFooter />,
			[PAGE_ID.COMPONENT_OSMOTIC_WATER_ALERTS]: <AlertsFooter />,
			[PAGE_ID.COMPONENT_GERMINATION_ALERTS]: <AlertsFooter />,
			[PAGE_ID.FARM_METADATA]: <MetadataTableFooter />,

		}[locationSa.currentPage as string] ?? null
	}, [locationSa.currentPage])

	// RENDER
	if (!Buttons || recipeDetailSo.state.readOnly) return null

	return (
		<Box data-test="footer" sx={cssContainer} >
			{Buttons}
		</Box>
	)
}

export default PageFooter

const cssContainer = {
	position: "sticky", bottom: "0px",
	//marginLeft: "26px", 
	minHeight: `${theme.app.footer.height}px`,
	display: "flex", alignItems: "center",
	backgroundColor: theme.palette.black.main,
	borderTop: `1px solid ${theme.palette.background.header}`,
	zIndex: theme.app.footer.zIndex,
}
