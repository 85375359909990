import { createStore, StoreCore } from "@priolo/jon"
import farmsApi from "api/farms"
import routeSo from "stores/route/query"
import valuesSo from "stores/values"
import { Farm } from "types/Farm"
import { GrowUnit } from "types/GrowUnit"
import { Component, COMPONENT_TYPE, GrowUnitComponent } from "types/Service"
import { normalizeFarm } from "./utils"



const isPLC = import.meta.env.VITE_TARGET == "plc"

/**
 * Gestione della lista delle FARMS
 */
const setup = {

	state: {
		/**  all the FARMs */
		all: <Farm[]>[],
		/** the selected FARMs */
		select: <Farm>null,

		/** il componente selezionato */
		componentSelect: <Component>null,
		/** PLC */
		//sharedServices: <SharedServices>null,
	},

	getters: {
		getFiltered: (_: void, store?: FarmStore): Farm[] => {
			let farms = [...store.state.all]
			farms = store.getFilteredExt(routeSo.getSearchUrl("search"))
			farms = routeSo.getSorted({ items: farms })
			return farms
		},
		getFilteredExt: (text: string, store?: FarmStore): Farm[] => {
			let farms = [...store.state.all]
			const txt = text?.trim().toLowerCase()
			if (txt && txt.length > 0) {
				farms = farms.filter(farm =>
					farm.name.toLowerCase().indexOf(txt) != -1
					|| farm.farmCode.toLowerCase().indexOf(txt) != -1
				)
			}
			return farms
		},
		/**  
		 * inserendo un array di id-farm restituisce una stringa con tutti i nomi delle famr
		 "farm bologna, farm1, farm2"
		*/
		getNames: (ids: number[], store?: FarmStore): string => ids
			?.map(id => store.state.all.find(farm => farm.id == id)?.name)
			.filter(name => name)
			.join(", ") ?? "<nothing>",

		getById: (id: number, store?: FarmStore): Farm => store.state.all.find(farm => farm.id == id),

		getByIds: (ids: number[], store?: FarmStore): Farm[] => ids.reduce((acc, id) => {
			const farm = store.getById(id)
			if (farm) acc.push(farm)
			return acc
		}, []),

		getDetails: (farm: Farm, store?: FarmStore) => `${farm.farmCode} ${farm.address} (${farm.zipCode}) ${farm.country}`,

		getGrowUnit: (id: number, store?: FarmStore): GrowUnit => id && store.state.select?.growUnits?.find(g => g.id == id),
		getGrowUnitByCode: (code: string, store?: FarmStore) => !!code && store.state.select?.growUnits?.find(g => g.growUnitCode == code),
		getGrowUnitByComponentSelect: (_: void, store?: FarmStore) => store.getGrowUnitByCode((<GrowUnitComponent>store.state.componentSelect)?.options?.code),
		getGrowUnitByComponentCode: (code: string, store?: FarmStore) => store.getGrowUnitByCode((<GrowUnitComponent>store.getComponentByCode(code))?.options?.code),

		getComponentOnAllFarms: (code: string, store?: FarmStore) => {
			if (!code) return {}
			for (const farm of store.state.all) {
				const component = farm?.edgeServicesConfig?.components?.find(c => c.code == code)
				if (!!component) return { farm, component }
			}
			return {}
		},
		/** il COMPONENT della FARM-SELECT con il "code" specificato */
		getComponentByCode: (code: string, store?: FarmStore) => 
			store.state.select?.edgeServicesConfig?.components?.find(c => c.code == code),
		/** tutti i COMPONENTS della FARM-SELECT con il "type" specificato  */
		getComponentsByType: (type: COMPONENT_TYPE, store?: FarmStore) => 
			store.state.select?.edgeServicesConfig?.components?.filter(c => c.type == type) ?? [],
		/** tutti i COMPONENTS della FARM-SELECT che sono in ascolto in una determinata coda NATS  */
		getComponentsByNats: (subject: string, store?: FarmStore) => 
			!!subject && store.state.select?.edgeServicesConfig?.components?.filter(c => c.subjects?.includes(subject) ?? false),
		/** il COMPONENT che gestisce la GROWUNIT identificata con il CODE */
		getComponentByGrowUnitCode: (code: string, store?: FarmStore) => {
			if (!code) return null
			return store.state.select?.edgeServicesConfig?.components?.find(s => s.type == COMPONENT_TYPE.GROWUNIT && (<GrowUnitComponent>s).options?.code == code)
		},

		/** sono in zero-os e ho la possibilità di accedere ai PLC-VALUES dal web */
		isInCloud: (_: void, store?: FarmStore) => !isPLC && !!store.state.select?.edgeServicesConfig?.cloudNats
	},

	actions: {
		fetchAll: async (_: void, store?: FarmStore) => {
			const { data } = await farmsApi.index()
			store.setAll(data)
		},
		fetchAllIfVoid: async (_: void, store?: FarmStore) => {
			if (store.state.all?.length > 0) return
			await store.fetchAll()
		},
		fetchSelect: async (id: number, store?: FarmStore) => {
			const { data: farm } = await farmsApi.get(id)
			store.setSelect(normalizeFarm(farm))
			return farm
		},
		fetchSelectIfVoid: async (id: number, store?: FarmStore) => {
			if (store.state.select && store.state.select.id == id) return store.state.select;
			return await store.fetchSelect(id)
		},


		//#region *** PLC ***

		/** preleva la FARM CORRENTE */
		fetchCurrent: async (_: void, store?: FarmStore) => {
			const { data: farm } = await farmsApi.current()
			store.setSelect(farm)
			document.title = `F2OS -${farm?.farmCode ?? ""}`
			return farm
		},
		fetchCurrentIfVoid: async (_: void, store?: FarmStore) => {
			if (store.state.select) return store.state.select
			return await store.fetchCurrent()
		},
		// fetchSharedServices: async (_: void, store?: FarmStore) => {
		// 	if (store.state.sharedServices) return store.state.sharedServices
		// 	const { data: ss } = await farmsApi.sharedServices()
		// 	store.setSharedServices(ss)
		// 	return ss
		// },

		//#endregion



	},

	mutators: {
		setAll: (all: Farm[]) => ({ all }),
		setSelect: (select: Farm) => ({ select }),
		//setSharedServices: (sharedServices: SharedServices) => ({ sharedServices }),
		setComponentSelect: (componentSelect: Component) => {
			valuesSo.state.serviceCodeSel = componentSelect?.subjects?.[0]
			return { componentSelect }
		},
	},
}



export type FarmState = typeof setup.state
export type FarmGetters = typeof setup.getters
export type FarmActions = typeof setup.actions
export type FarmMutators = typeof setup.mutators
export interface FarmStore extends StoreCore<FarmState>, FarmGetters, FarmActions, FarmMutators {
	state: FarmState
}
const farmSo = createStore(setup) as FarmStore
export default farmSo

