import { createStore, StoreCore } from "@priolo/jon";
import incidentsApi from "api/incidents";
import i18n from "i18next";
import dialogSo, { DIALOG_TYPE } from "stores/layout/dialogStore";
import { Incident, IncidentType } from "types/Incident";



const setup = {

	state: {
		/** tutti gli INCIDENTS */
		all: <Incident[]>[],
		/** INCIDENT selezionato in questo momento */
		select: <Incident>null,
		/** tutti i tipi di INCIDENTS */
		types: <IncidentType[]>[],

		/** l'id FARM dell'ultima "caricata" di INCIDENTS*/
		lastFarmId: <number>null,

		/** indica che la dialog per l'editazione dell INCIDENT è aperto */
		editDialogOpen: <boolean>false,
	},

	getters: {
		getByCycleId: (cycleId: number, store?: IncidentStore) => {
			return store.state.all.filter(i => i.cycleId == cycleId)
		},
		getLabelType: (typeId: string, store?: IncidentStore) => {
			const type = store.state.types.find(t => t.id == typeId)
			return type?.label ?? "--"
		},
	},

	actions: {
		/** Carico tutti i gli INCIDENT di una specifica FARM */
		/** carico tutti i CROPS */
		fetchAll: async (farmId: number, store?: IncidentStore) => {
			const { data } = await incidentsApi.index(farmId)
			store.setAll(data)
		},
		fetchAllIfVoid: async (farmId: number, store?: IncidentStore) => {
			if (store.state.lastFarmId == farmId && store.state.all?.length > 0) return
			await store.fetchAll(farmId)
		},
		fetchSelect: async (id: number, store?: IncidentStore) => {
			const { data } = await incidentsApi.get(id)
			store.setSelect(data)
		},
		fetchTypes: async (_: void, store?: IncidentStore) => {
			const { data } = await incidentsApi.types()
			store.setTypes(data)
		},
		fetchTypesIfVoid: async (_: void, store?: IncidentStore) => {
			if (store.state.types?.length > 0) return
			await store.fetchTypes()
		},

		edit: (incident: Incident, store?: IncidentStore) => {
			if (!incident) return
			store.setSelect(incident)
			store.setEditDialogOpen(true)
		},
		save: async (_: void, store?: IncidentStore) => {
			const { data: incident } = await incidentsApi.save(store.state.select)

			if (incident.cycleId == null) incident.cycleId = store.state.select.cycleId

			const index = store.state.all.findIndex(i => i.id == incident.id)
			if (index == -1) store.state.all.push(incident)
			else store.state.all[index] = incident
			store.setAll([...store.state.all])
		
			store.setEditDialogOpen(false)
			store.setSelect(null)
			dialogSo.dialogOpen({ type: DIALOG_TYPE.INFO, modal: false, text: i18n.t("snackbar.default.save") })
		},
		revert: async (incident: Incident, store?: IncidentStore) => {
			const { data } = await incidentsApi.revert(incident.id)
			incident.reverted = true
			const index = store.state.all.findIndex(i => i.id == incident.id)
			store.state.all[index] = incident
			store.setSelect(data)
		},
		proceeded: async (incident: Incident, store?: IncidentStore) => {
			const { data } = await incidentsApi.proceeded(incident.id)
			incident.reverted = false
			const index = store.state.all.findIndex(i => i.id == incident.id)
			store.state.all[index] = incident
			store.setSelect(data)
		},
		delete: async (incident: Incident, store?: IncidentStore) => {
			await incidentsApi.destroy(incident.id)
			store.setAll(store.state.all.filter(i => i.id != incident.id))
			store.setSelect(null)
		},
	},

	mutators: {
		setAll: (all: Incident[]) => ({ all }),
		setSelect: (select: Incident) => ({ select }),
		setEditDialogOpen: (editDialogOpen: boolean) => ({ editDialogOpen }),
		setTypes: (types: IncidentType[]) => ({ types }),
	},
}


export type IncidentState = typeof setup.state
export type IncidentGetters = typeof setup.getters
export type IncidentActions = typeof setup.actions
export type IncidentMutators = typeof setup.mutators
export interface IncidentStore extends StoreCore<IncidentState>, IncidentGetters, IncidentActions, IncidentMutators {
	state: IncidentState
}
const incidentSo = createStore(setup) as IncidentStore
export default incidentSo