import { Add as AddIcon, FileDownload } from "@mui/icons-material";
import ButtonCmp, { BTT_SHAPE } from "components/controls/buttons/ButtonCmp";
import ExportDialog from "components/controls/ExportDialog";
import { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import metaSo from "stores/metadata";
import userSo from "stores/user";



const MetadataTableFooter: FunctionComponent = () => {

	// STORE
	const [dialogExportOpen, setDialogExportOpen] = useState(false)

	// HOOKs
	const { t } = useTranslation()

	// HANDLERs
	const handleNew = () => metaSo.openMetaDialog({})

	// RENDER
	if (!userSo.isAdmin()) return null
	
	return <>
		<ButtonCmp sx={{ ml: "26px" }}
			shape={BTT_SHAPE.MAIN}
			startIcon={<AddIcon />}
			onClick={handleNew}
		>{t("footer.meta.new")}</ButtonCmp>

		<ButtonCmp sx={{ ml: 1 }}
			startIcon={<FileDownload />}
			onClick={() => setDialogExportOpen(true)}
		>EXPORT CSV</ButtonCmp>

		<ExportDialog
			isOpen={dialogExportOpen}
			onClose={() => setDialogExportOpen(false)}
			onInitData={metaSo.getExportData}
		/>
	</>
}

export default MetadataTableFooter