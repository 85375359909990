import { Box, Typography } from "@mui/material";
import PageIcon from "components/layout/menu/PageIcon";
import React, { FunctionComponent } from "react";
import { MenuItem } from "stores/mainMenu/types";
import { getHeaderTitleByPageId } from "stores/route/utils/pagesId";
import palette from "styles/palette";
import ParentMenu from "./ParentMenu";



const isPLC = import.meta.env.VITE_TARGET == "plc"

interface Props {
	item: MenuItem,
	onClickIcon?: () => void
}

const ParentItem: FunctionComponent<Props> = ({
	item,
	onClickIcon,
}) => {

	// STORE

	// HOOKs

	// ricava i dati da visualizzare
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

	// HANDLERS
	const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	}
	const handleCloseMenu = () => {
		setAnchorEl(null);
	}

	// RENDER
	const title = getHeaderTitleByPageId(item.id)
	const open = Boolean(anchorEl)
	const cssLabel = {
		m: "2px 7px 0px 5px",
		cursor: "pointer", //menuItems.length > 0 ? "pointer" : "default"
	}

	return <>
		<Box sx={{ display: "flex", alignItems: "center", fill: palette.text.primary }}>
			<PageIcon pageId={item.id} onClick={onClickIcon} sx={{ cursor: "pointer" }} />
			<Typography variant="h5" sx={cssLabel}
				onClick={handleOpenMenu}
			>
				{title}
			</Typography>
		</Box>

		<ParentMenu
			anchorEl={anchorEl}
			open={open}
			onClose={handleCloseMenu}
			item={item} 
		/>
	</>
}

export default ParentItem
