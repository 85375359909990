import { SxProps } from "@mui/material";
import { FunctionComponent, useMemo, useState } from "react";
import SelectorDialogBase from "./SelectorDialogBase";
import WrapControl, { ICON_VARIANT, WrapControlProps } from "./WrapControl";



interface Props extends WrapControlProps {
	title?: string
	icon?: React.ReactNode
	placeholder?: string

	/** items da visualizzare nella dialog */
	items: any[]
	/** l'identificativo selezionato */
	idSelect: string | number,
	/** quando si seleziona e chiude  */
	onChange: (item: any) => void

	/** per un "item" restituisce il suo "text" */
	fnTextFromItem?: (item: any) => React.ReactNode,
	/** per un "item" restituisce il suo "sottotitolo" */
	fnSecondaryFromItem?: (item: any) => React.ReactNode,
	/** a fronte di un item restituisce il suo ID */
	fnIdFromItem?: (item: any) => number | string,

	sx?: SxProps
}

const SelectorBase: FunctionComponent<Props> = ({
	title,
	icon,
	placeholder,
	items,
	idSelect,
	onChange,
	fnTextFromItem = (item) => item,
	fnSecondaryFromItem,
	fnIdFromItem = (item) => item,
	sx
}) => {

	// STORE

	// HOOKs
	const [isOpen, setIsOpen] = useState(false)

	// HANDLER
	const handleClick = () => setIsOpen(true)
	const handleClose = (item: any) => {
		setIsOpen(false)
		onChange?.(item)
	}

	// RENDER
	const getIndexFromId = (id: string | number) => {
		if (!items || !id) return -1
		return items.findIndex(item => fnIdFromItem(item) == id)
	}
	const label = useMemo(() => {
		const item = items[getIndexFromId(idSelect)]
		return !!item ? (fnTextFromItem(item) ?? "") : ""
	}, [idSelect])

	return (<>
		<WrapControl sx={sx}
			variantIcon={ICON_VARIANT.PAGE}
			textValue={label}
			onClick={handleClick}
			placeholder={placeholder}
		/>
		<SelectorDialogBase
			title={title}
			icon={icon}
			width={"auto"}
			height={null}
			items={items}
			idSelect={idSelect}
			isOpen={isOpen}
			onClose={handleClose}
			fnTextFromItem={fnTextFromItem}
			fnSecondaryFromItem={fnSecondaryFromItem}
			fnIdFromItem={fnIdFromItem}
		/>
	</>)
}

export default SelectorBase

