import { Category } from "@mui/icons-material";
import React, { FunctionComponent, useMemo, useState } from "react";
import { METADATA_FILTER_PROP } from "stores/metadata/utils/utils";
import SelectorDialogBase from "../base/SelectorDialogBase";
import Chip from "./Chip";



interface Props {
	feature?: string,
	onChange: (feature: string) => void,
	sx?: any
}

const SelectorMetaFeature: FunctionComponent<Props> = ({
	feature,
	onChange,
	sx,
}) => {

	// STORE


	// HOOKs
	const [isOpen, setIsOpen] = useState(false)

	// HANDLER
	const handleClick = () => {
		setIsOpen(true)
	}
	const handleClose = (feature: string) => {
		setIsOpen(false)
		onChange?.(feature)
	}
	const handleClear = (e: React.MouseEvent) => {
		e.stopPropagation()
		setIsOpen(false)
		onChange?.(null)
	}

	// RENDER
	const features = useMemo(() => Object.values(METADATA_FILTER_PROP), [])
	const isActive = feature != null

	return (<>
		<Chip sx={sx}
			label={feature ?? "ALL FEATURES"}
			icon={<Category />}
			//tooltip={t("selector.service.tooltip")}
			active={isActive}
			onClick={handleClick}
			onClear={handleClear}
		/>
		<SelectorDialogBase
			width="auto" height={null}

			icon={<Category />}
			items={features}
			idSelect={feature}
			title={"FEATURE"}
			isOpen={isOpen}
			onClose={handleClose}
			onClear={handleClear}

			fnTextFromItem={item => item ?? "ALL FEATURES"}
			fnIdFromItem={item => item}
		/>
	</>)
}

export default SelectorMetaFeature
