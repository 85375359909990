import { createStore, StoreCore } from "@priolo/jon"
import { MenuItem } from "./types"
import theme from "styles/theme"
import { delay } from "utils"
import { ITEMS_MAIN as ITEMS_OS } from "./tree_os"
import { ITEMS_MAIN as ITEMS_PLC } from "./tree_plc"

const ITEMS_MAIN = import.meta.env.VITE_TARGET == "os" ? ITEMS_OS : ITEMS_PLC



export enum AnimSectionsState {
	NORMAL = "normal",
	HIDE = "hide",
	SHOW = "show",
}

/**
 * Gestione Main Menu laterale 
 * la parte inferiore delle SECTIONS
 * - animazioni
 * - contenuti
 */
const setup = {

	state: {
		items: ITEMS_MAIN,
		animState: AnimSectionsState.NORMAL,
		selected: 0,
	},

	getters: {
	},

	actions: {
		/** aggiorno gli ITEMS presenti in sections */
		update: (items: MenuItem[], store?: SectionsStore) => {
			const fnAnim = async () => {
				const duration = theme.transitions.duration.standard

				// se è uguale al precedente non fare nulla
				if (items.every((i, index) => i.id == store.state.items[index]?.id)) return

				store.setAnimState(AnimSectionsState.HIDE)
				await delay(duration)
				store.setItems(items)
				store.setAnimState(AnimSectionsState.SHOW)
				await delay(duration)
				store.setAnimState(AnimSectionsState.NORMAL)
			}
			fnAnim()
		},
		/** sposto il selector sull'ITEM selezionato */
		select: (index: number, store?: SectionsStore) => {
			//if ( index == -1 ) return
			//const item = store.state.items[index]

			// if (item.children && item.isDir) {
			// 	//store.selectionEnter(index)
			// } else {
			store.setSelected(index)
			//}
		},
	},

	mutators: {
		setItems: (items: MenuItem[]) => ({ items }),
		setAnimState: (animState: AnimSectionsState) => ({ animState }),
		setSelected: (selected: number) => ({ selected }),
	},
}

export type SectionsState = typeof setup.state
export type SectionsGetters = typeof setup.getters
export type SectionsActions = typeof setup.actions
export type SectionsMutators = typeof setup.mutators
export interface SectionsStore extends StoreCore<SectionsState>, SectionsGetters, SectionsActions, SectionsMutators {
	state: SectionsState
}
const store = createStore(setup) as SectionsStore
export default store