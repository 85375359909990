import { Box, Divider, SxProps } from "@mui/material"
import { useStore } from "@priolo/jon"
import { FunctionComponent, useMemo } from "react"
import { useHistory } from "react-router-dom"
import parentsSo from "stores/mainMenu/parents"
import { getByPageId } from "stores/mainMenu/utils"
import { MenuItem } from "stores/mainMenu/types"
import { getUrlByPageId } from "stores/route/utils/pagesId"
import ParentItem from "./ParentItem"
import theme from "styles/theme"



const isPLC = import.meta.env.VITE_TARGET == "plc"

interface Props {
	sx: any
}

const MenuParents: FunctionComponent<Props> = ({
	sx
}) => {

	// STORE
	const parentsSa = useStore(parentsSo)

	// HOOKs
	const history = useHistory()
	const override: MenuItem = useMemo(() => {
		const page = getByPageId(parentsSa.overwritePage)
		return page?.sections?.[page?.index]
	}, [parentsSa.overwritePage])

	// HANDLERs
	const handleClick = (item: MenuItem) => {
		const url = getUrlByPageId(override?.id ?? item.id)
		parentsSo.setOverwritePage(null)
		if (url) history.push(url)
	}

	// RENDER
	let items = parentsSa.items
	// se c'e' l'override visualizzo solo l'ultimo
	if ( override ) {
		items = parentsSa.items?.slice(-1)
	} else {
		// per la versione ZERO-OS tolgo i primo parent perche' la root non la visualizzo
		if ( !isPLC ) items = parentsSa.items?.slice(1)
	}
	
	if (!(items?.length > 0)) return null
	const cnContainer = [sxContainer, { height: `${parentsSa.height}px` }, sx]

	return <Box sx={cnContainer}>
		<Box sx={sxList}>
			{items.map((item, index) => (
				<ParentItem key={item.id.toString()}
					index={index}
					item={item}
					override={override}
					onClick={() => handleClick(item)}
				/>
			))}
		</Box>
	</Box>
}

export default MenuParents

const sxContainer: SxProps = {
	overflow: "hidden",
	transition: theme.transitions.create(['height']),
}

const sxList: SxProps = {
	display: "flex",
	flexDirection: "column",
}
