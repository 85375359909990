import { Box, SxProps, Typography } from "@mui/material";
import PageIcon from "components/layout/menu/PageIcon";
import { FunctionComponent } from "react";
import { MenuItem } from "stores/mainMenu/types";
import palette from "styles/palette";


interface Props {
	item: MenuItem
	isRoot?: boolean
}

const SelectItem: FunctionComponent<Props> = ({
	item,
	isRoot,
}) => {

	// STORE

	// HOOKs

	// RENDER
	const variant = isRoot ? "h5" : "subtitle1"
	const sxIcon: SxProps = {
		fill: palette.text.primary,
		...isRoot ? {} : { width: "18px", height: "18px" },
	}
	const sxRoot: SxProps = {
		display: "flex", alignItems: "center", opacity: isRoot ? 1 : 0.5
	}

	return (
		<Box sx={sxRoot}>

			<PageIcon pageId={item.id} sx={sxIcon} />

			<Typography 
				variant={variant} 
				sx={{ m: "2px 7px 0px 5px" }}
			>
				{item.title}
			</Typography>
		</Box>
	)
}

export default SelectItem
