import { Badge, SxProps, Tooltip, Typography } from "@mui/material"
import { Box, positions } from "@mui/system"
import { FunctionComponent } from "react"
import { MenuItem } from "stores/mainMenu/types"
import theme from "styles/theme"
import PageIcon from "./PageIcon"
import drawerSo from "stores/mainMenu/drawer"
import { useStore } from "@priolo/jon"



interface Props {
	item: MenuItem

	select?: boolean
	badge?: string
	highlight?: boolean
	tooltip?: string
	disabled?: boolean

	onClick?: () => void
}

const SectionItem: FunctionComponent<Props> = ({
	item,

	select = false,
	badge = null,
	highlight = false,
	tooltip = null,
	disabled,

	onClick,
}) => {

	// STORES
	const drawerSa = useStore(drawerSo)

	// HOOKs

	// HANDLER
	const handleClick = () => {
		onClick()
	}

	// RENDER
	const cnContainer: SxProps = [
		cssContainer,
		select && cssSelect,
		highlight && cssHighlight(select),
		disabled && cssDisabled,
		{ position: "relative" },
	]
	tooltip = drawerSa.isCompact ? item.title : tooltip

	return (
		<Box
			sx={cnContainer as any}
			data-test={`mainmenu-${item.id}`}
			onClick={!disabled ? handleClick : undefined}
		>
			<Tooltip placement="right" title={tooltip}>
				<Badge color="secondary" sx={cssBadge} variant="dot" badgeContent={badge} invisible={!badge}>
					<Box sx={cssIcon}><PageIcon pageId={item.id} /></Box>
				</Badge>
			</Tooltip>

			<Box sx={cssLabel(drawerSa.isCompact)}>
				{item.title}
			</Box>

			{item.divider && (
				<div style={{ position: "absolute", bottom: 0, left: 10, right: 10, height: 1, backgroundColor: theme.palette.divider }} />
			)}

		</Box>
	)
}

export default SectionItem

const cssContainer: SxProps = {
	//height: 24, margin: "7px 7px 7px 15px",
	height: 38, padding: "7px 0px 7px 10px",
	display: "flex", alignItems: "center",
	cursor: "pointer",

	fill: theme.palette.text.primary,
	borderRight: `0px solid ${theme.palette.secondary.main}`,
	transition: theme.transitions.create(['color', 'fill', 'border-right']),
}

const cssSelect: SxProps = {
	color: theme.palette.background.card,
	fill: theme.palette.background.card,
	borderRightColor: "#ffe491",
}

const cssDisabled: SxProps = {
	cursor: "default",
	opacity: .5,
}

const cssHighlight = (select: boolean): SxProps => ({
	borderRightWidth: "9px",
	color: select ? undefined : theme.palette.secondary.main
})

const cssIcon: SxProps = {
	width: 24,
	height: 24,
	mr: "5px",
}
const cssLabel = (compact: boolean): SxProps => ({
	flex: 1,
	fontSize: "12px",
	fontWeight: 500,
	opacity: compact ? 0 : 1,
	transition: theme.transitions.create(["opacity"]),
})

const cssBadge: SxProps = {
	'& .MuiBadge-badge': {
		top: "-1px",
		right: "10px",
		//fontSize: "10px",
		//padding: 0,
		boxShadow: "1px 2px 2px 0 rgb(0 0 0 / 28%)",
	},
}