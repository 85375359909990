import { StoreCore, createStore } from "@priolo/jon"
import authApi from "api/auth"
import authPasswordApi from "api/authPassword"
import i18n from "i18next"
import dialogSo, { DIALOG_TYPE } from "stores/layout/dialogStore"
import tfaSo from "stores/user/tfa"
import { User } from "types/User"
import { Auth, LOGIN_RESPONSE } from "./utils"



const isPLC = import.meta.env.VITE_TARGET == "plc"

const setup = {

	state: {
		/** the user is currently logged in */
		current: <User>null,
		/** selected user */
		select: <User>null,
		//#region FORM
		email: "",
		password: "",
		//#endregion
		/** indicates whether the profile menu is open or not */
		menuOpen: false,
		repassword: "",
	},

	getters: {
		/** restituisce true se è stato fatto correttamente il login */
		isLogged: (_: void, store?: UserStore) => {
			return !!store.state.current
		},
		/** restituisce true se l'utente correntemente loggato è admin */
		isAdmin: (_: void, store?: UserStore) => {
			return store.state.current?.isAdmin ?? false
		},
		/** restituisce true se l'utente correntemente loggato è un debugger */
		isDebugger: (_: void, store?: UserStore) => {
			return store.state.current?.debugEnabled ?? false
		},
		plcAccess: (_: void, store?: UserStore) => {
			if (isPLC) return true
			return store.state.current?.hasPlcAccess ?? false
		},
	},

	actions: {

		/** retrieves the currently logged in user */
		fetch: async (_: void, store?: UserStore) => {
			const auth = Auth.get()
			if (!auth) {
				store.setCurrent(null)
				return
			}
			let user: User
			try {
				const { data } = await authApi.getCurrent()
				user = data
				store.setCurrent(user)
			} catch (err) {
				store.logout()
			}
			return user
		},

		/** log in with the status values set "email" and "password" */
		login: async (_: void, store?: UserStore) => {
			// recupero il token
			let response: any
			try {
				response = await authApi.login(
					store.state.email,
					store.state.password,
					tfaSo.state.otpCode
				)
				// se c'e' un errore cntrollo se c'e' bisogno di OTP
			} catch (error) {
				const otpRequiredForLogin = error.data?.otpRequiredForLogin
				if (otpRequiredForLogin) return LOGIN_RESPONSE.NEEDS_OTP
				dialogSo.dialogOpen({
					type: DIALOG_TYPE.ERROR,
					title: i18n.t("dialog.ajax.401.title"),
					text: i18n.t(`dialog.ajax.401.${error?.error?.includes("OTP") ? "otp-code" : "login"}.text`)
				})
				return LOGIN_RESPONSE.ERROR
			}
			// setto il token
			Auth.set(response)
			// recupero l'utente corrente
			const user = await store.fetch()
			if (!user) return LOGIN_RESPONSE.ERROR
			store.resetSecret()
			// se era un login con otp-code allora lo setto ad abilitato
			if (tfaSo.state.otpCode) {
				tfaSo.setIs2FAEnabled(true)
				tfaSo.setIs2FAConfigured(true)
				// se l'otp non è configurato e l'utente lo deve abilitare...
			} else if (user.forceEnabledTwoFa) {
				return LOGIN_RESPONSE.FORCE_2FA
			}
			return LOGIN_RESPONSE.LOGGED
		},

		/** Log out */
		logout: async ({ noHttp, reload }: { noHttp?: boolean, reload?: boolean } = {}, store?: UserStore) => {
			store.setMenuOpen(false)
			if (!noHttp && store.isLogged()) await authApi.logout()
			Auth.remove()
			if (reload) {
				window.location.reload()
			} else {
				store.setCurrent(null)
			}
		},

		/** Retrieve a user using the recovery token */
		fetchUserWithRecoveryToken: async (token: string, store?: UserStore) => {
			store.setSelect(null)
			const { data: user } = await authPasswordApi.getUserByToken(token)
			store.setSelect(user)
		},

		/** send an email with instructions to change the password */
		sendRequestChangePassword: async (_: void, store?: UserStore) => {
			await authPasswordApi.getEmailInstructions(store.state.email)
		},

		/**
		 * change the password via a token
		 * {"password":{"password":"password","password_confirmation":"password", "reset_password_token":"c8RfivTxQ4yzK1kFdMLt"}}
		 */
		sendResetPassword: async (token: string, store?: UserStore) => {
			await authPasswordApi.resetPassword(store.state.password, store.state.repassword, token)
			store.resetSecret()
		},
	},

	mutators: {
		setCurrent: (current) => ({ current }),
		setSelect: (select) => ({ select }),
		setMenuOpen: (menuOpen) => ({ menuOpen }),

		setEmail: (email) => ({ email }),
		setPassword: (password) => ({ password }),
		resetSecret: () => {
			tfaSo.setOtpCode("")
			tfaSo.setOtpSecret(null)
			tfaSo.setQRCode(null)
			return {
				password: "",
				repassword: "",
			}
		},
		setRePassword: (repassword) => ({ repassword }),
	},

}

export type UserState = typeof setup.state
export type UserGetters = typeof setup.getters
export type UserActions = typeof setup.actions
export type UserMutators = typeof setup.mutators
export interface UserStore extends StoreCore<UserState>, UserGetters, UserActions, UserMutators {
	state: UserState
}
const userSo = createStore(setup) as UserStore
export default userSo