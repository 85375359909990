import { Subscription, createConsumer } from "@rails/actioncable"
import humps from "humps"



const isPLC = import.meta.env.VITE_TARGET == "plc"
const cableUrl = import.meta.env.VITE_API_CABLE_HOST ?? "/cable"
const consumer = createConsumer(cableUrl)

export enum CHANNELS {
	EVENTS = "EventChannel",
	FARMS = "FarmStatusChannel",
	GROWUNITS = "GrowUnitStatusChannel",
	/** PRODUCTION REQUEST */
	REQUESTS = "PlannerCyclesRequestStatusChannel",
	DRAFT = "PlannerCyclesDraftStatusChannel",
	CYCLETASKS = "CycleTasksChannel"
}

export class Channel {
	name: string
	options: {}
	listeners: Listener[]
	channel: Subscription

	constructor(name: string, options: any = {}) {
		this.name = name
		this.options = options
		this.listeners = []
		this.channel = null
	}

	createChannel(): Promise<void> {
		if (isPLC) return Promise.resolve()
		return new Promise((resolve) => {

			let resolved = false
			const resolvePromise = () => {
				if (!resolved) {
					resolved = true
					clearTimeout(fallback)
					resolve()
				}
			}
			const fallback = setTimeout(resolvePromise, 2000)

			this.channel = consumer.subscriptions.create(
				{
					channel: this.name,
					...this.options
				},
				{
					connected: resolvePromise,
					disconnected: resolvePromise,
					rejected: resolvePromise,
					received: this.onReceiveData.bind(this)
				}
			)
		})
	}

	destroyChannel() {
		if (!this.channel || isPLC) return
		this.channel.unsubscribe()
		this.listeners = []
		this.channel = null
	}

	onReceiveData(data: any) {
		data = humps.camelizeKeys(data)
		this.listeners.forEach(listener => listener(data))
	}

	async subscribe(listener: Listener) {
		if (isPLC) return
		this.listeners.push(listener)
		if (!this.channel) {
			await this.createChannel()
		}
	}

	unsubscribe(listener: Listener) {
		if (isPLC) return
		this.listeners = this.listeners.filter(l => l != listener)
		if (this.listeners.length == 0) this.destroyChannel()
	}

}

export const eventChannel = new Channel(CHANNELS.EVENTS)

type Listener = (data: any) => void
