import React, { FunctionComponent, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import SelectorDialogBase from "../base/SelectorDialogBase";
import { Workspaces as GroupIcon } from "@mui/icons-material";
import Chip from "./Chip";
import metaSo from "stores/metadata"



interface Props {
	group?: string,
	onChange: (group: string) => void,
	sx?: any
}

const SelectorMetaGroup: FunctionComponent<Props> = ({
	group,
	onChange,
	sx,
}) => {

	// STORE


	// HOOKs
	const { t } = useTranslation()
	const [isOpen, setIsOpen] = useState(false)

	// HANDLER
	const handleClick = () => {
		setIsOpen(true)
	}
	const handleClose = (group: string) => {
		setIsOpen(false)
		onChange?.(group)
	}
	const handleClear = (e: React.MouseEvent) => {
		e.stopPropagation()
		setIsOpen(false)
		onChange?.(null)
	}

	// RENDER
	const groups = useMemo(() => [null, ...metaSo.state.groups], [metaSo.state.groups])
	const isActive = group != null

	return (<>
		<Chip sx={sx}
			label={group ?? "ALL GROUPS"}
			icon={<GroupIcon />}
			tooltip={t("selector.group.tooltip")}
			active={isActive}
			onClick={handleClick}
			onClear={handleClear}
		/>
		<SelectorDialogBase
			width="auto"
			icon={<GroupIcon />}
			items={groups}
			idSelect={group}
			title={t("selector.group.title")}
			isOpen={isOpen}
			onClose={handleClose}
			onClear={handleClear}

			fnTextFromItem={item => item ?? "ALL GROUPS"}
			fnIdFromItem={item => item}
		/>
	</>)
}

export default SelectorMetaGroup
