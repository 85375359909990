import { useStore } from "@priolo/jon";
import GrowUnitIcon from "images/menu/growunit";
import { FunctionComponent, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import farmSo from "stores/farm";
import { getFiltered } from "stores/growUnit/utils";
import { GrowUnit } from "types/GrowUnit";
import SelectorDialogBase from "../base/SelectorDialogBase";
import WrapControl, { ICON_VARIANT, WrapControlProps } from "../base/WrapControl";



interface Props extends WrapControlProps {
	/** la farm da cui prendere le GrowUnit, se non è inserita la prendo da farmSo.select*/
	farmId?: number,
	/** id delle GROW-UNIT selezionata */
	growUnitId: number,
	/** quando si seleziona una GROW-UNIT  */
	onChange: (growUnit: GrowUnit) => void,
}

const SelectorGrowUnit: FunctionComponent<Props> = ({
	farmId,
	growUnitId,
	onChange,
	...props
}) => {

	// STORE
	const farmSa = useStore(farmSo)

	// HOOKs
	const { t } = useTranslation()
	const [isOpen, setIsOpen] = useState(false)
	const [textFilter, setTextFilter] = useState("")

	// HANDLER
	const handleClick = () => {
		setIsOpen(true)
	}
	const handleClose = (growUnit:any) => {
		setIsOpen(false)
		if (growUnit) onChange?.(growUnit)
	}
	const handleClear = () => {
		setIsOpen(false)
		onChange?.(null)
	}

	// RENDER
	const farm = useMemo(() => {
		return !farmId ? farmSa.select : farmSo.getById(farmId)
	}, [farmId, farmSa.all])
	const growUnits = useMemo(()=> getFiltered( farm?.growUnits, textFilter), [farm?.growUnits, textFilter])
	const growUnitSelect = useMemo(() => growUnits?.find(gu => gu.id == growUnitId), [growUnits, growUnitId])
	if ( !growUnits ) return null
	
	return (<>
		<WrapControl
			variantIcon={ICON_VARIANT.PAGE}
			placeholder="Click to select"
			textValue={growUnitSelect?.name ?? ""}
			onClick={handleClick}
			onClickClear={handleClear}
			{...props}
		/>
		<SelectorDialogBase
			width="auto"
			height={null}
			icon={<GrowUnitIcon />}
			items={growUnits}
			textValue={textFilter}
			idSelect={growUnitId}
			title={t("selector.growunit.title")}
			isOpen={isOpen}
			onClose={handleClose}
			onClear={handleClear}
			onChangeTextValue={txt => setTextFilter(txt)}
			fnTextFromItem={item => item?.name}
			fnIdFromItem={item => item.id}
		/>
	</>)
}

export default SelectorGrowUnit

