import { createStore, resetAll, StoreCore } from "@priolo/jon";
import metaApi from "api/metadata";
import { ExportCSVData } from "components/controls/ExportDialog";
import dayjs from "dayjs";
import i18n from "i18next";
import farmSo from "stores/farm";
import dialogSo, { DIALOG_TYPE } from "stores/layout/dialogStore";
import querySo from "stores/route/query";
import { URL_PAR } from "stores/route/utils/types";
import valuesSo from "stores/values";
import { Metadata } from "types/Metadata";
import { Values } from "types/Value";
import { clone } from "utils/func";
import { filter, formatFromMeta, formatToMeta, MetaTypeFormat } from "./utils/utils";



const isPLC = import.meta.env.VITE_TARGET == "plc"

// const tableByName = {}
const tableByAlias: { [alias: string]: Metadata } = {}

const setup = {

	state: {
		/** i METADATA selezionati*/
		metadata: <Metadata[]>null,
		lastKey: <string>null,
		metadataFarms: <{ [key: string]: Metadata[] }>{},

		/** METADATA selezionato in DIALOG */
		inEdit: <Metadata>null,
		/** formato potabile usato nella DIALOG */
		format: <MetaTypeFormat>{},
		/** indica se la DIALOG è aperto o no */
		isDialogOpen: false,

		/** un array di GROUPs presenti nei METADATA */
		groups: <string[]>[],
		/** un array dei gruppi di "services code" presenti nei metadata */
		services: <string[]>[],
	},

	getters: {
		/** restituisce un METADATA tramite il suo name */
		getMetadata(
			{ serviceCode, name }: { serviceCode?: string, name: string },
			store?: MetadataStore
		) {
			return store.state.metadata[store.getIndex({ serviceCode, name })]
		},
		getIndex(
			{ serviceCode, name }: { serviceCode?: string, name?: string },
			store?: MetadataStore
		) {
			if (!serviceCode) serviceCode = valuesSo.state.serviceCodeSel
			if (!store.state.metadata || !serviceCode || !name) return -1
			return store.state.metadata.findIndex(meta => meta.name == name && meta.serviceCode == serviceCode)
		},
		/** restituisce un METADATA tramite il suo name usando l'hash */
		getMetadataByAlias(
			{ serviceCode, alias }: { serviceCode?: string, alias: string },
			store?: MetadataStore
		) {
			if (!serviceCode) serviceCode = valuesSo.state.serviceCodeSel
			if (!store.state.metadata || !serviceCode || !alias) return null
			const meta = tableByAlias[`${serviceCode.toUpperCase()} ${alias.toUpperCase()}`]
			return meta
		},
		/** per l'export in CSV */
		getExportData: (_: void, store?: MetadataStore): ExportCSVData => {
			const [filterText, group, service] = querySo.getSearchUrl([URL_PAR.TEXT, URL_PAR.META_GROUP, URL_PAR.META_SERVICE]) as string[]
			let items = filter(metaSo.state.metadata, filterText, group, service)
			items = querySo.getSorted({ items })
			const data = items.map(item => [
				item.serviceCode, item.name, item.alias, item.group, item.target, item.description, item.type
			])
			return {
				headers: [
					"SERVICE CODE", "NAME", "ALIAS", "GROUP", "TARGET", "DESCRIPTION", "TYPE"
				],
				items: data,
				fileName: `METADATA ${farmSo.state.select.name} (${dayjs().format("YYYY-MM-DD HH-mm")})`
			}
		},
	},

	actions: {
		/** recupera dal BE tutti i METADATA */
		async fetch(url?: string, store?: MetadataStore) {
			let metadata: Metadata[] = null
			if (isPLC) {
				metadata = (await metaApi.index())?.data
			} else {
				if (!url) url = store.state.lastKey
				metadata = (await metaApi.index(url))?.data
				if (!metadata) return
				store.state.lastKey = url
			}
			store.setupMetadata(metadata)
			return metadata
		},
		/** carico i METADATA se non sono stati gia' caricati */
		async fetchIfVoid(url?: string, store?: MetadataStore) {
			if (isPLC) {
				if (store.state.metadata?.length > 0) return store.state.metadata
				return store.fetch()
			}
			let metadata = store.state.metadataFarms[url]
			if (!metadata) {
				metadata = await store.fetch(url)
				if (!!url) store.state.metadataFarms[url] = metadata
			} else {
				store.setupMetadata(metadata)
			}
			return metadata
		},
		/**
		 * setta i METADATA per una specifica FARM
		 * imposta anche i gruppi e i services (ottimizzazione)
		 **/
		setupMetadata(metadata: Metadata[], store?: MetadataStore) {
			// GROUPS e SERVICES
			const groups: string[] = []
			const services: string[] = []
			for (let index = 0; index < metadata.length; index++) {
				const meta = metadata[index]
				if (meta.group && meta.group.length > 0 && !groups.includes(meta.group)) groups.push(meta.group)
				if (meta.serviceCode && meta.serviceCode.length > 0 && !services.includes(meta.serviceCode)) services.push(meta.serviceCode)
				if (!!meta.alias && !!meta.serviceCode) {
					tableByAlias[`${meta.serviceCode.toUpperCase()} ${meta.alias.toUpperCase()}`] = meta
				}
			}
			store.setGroups(groups)
			store.setServices(services)
			store.setMetadata(metadata)
		},
		/** visualizza il dettaglio di un META */
		openMetaDialog(metadata: Metadata, store?: MetadataStore) {
			if (!metadata) return
			const metaClone = clone(metadata)
			store.setInEdit(metaClone)
			store.setFormat(formatFromMeta(metaClone))
			store.setIsDialogOpen(true)
			resetAll()
		},

		/**
		 * Salva un METADATA e lo inserisce nella lista
		 */
		async save(_: void, store?: MetadataStore) {
			const meta = store.state.inEdit
			if (!meta.name || !meta.serviceCode) return
			meta.format = formatToMeta(store.state.format)
			delete meta._tmp

			const { data: newMetadata } = await metaApi.save(meta)

			const index = store.getIndex({ serviceCode: newMetadata.serviceCode, name: newMetadata.name })
			const isNew = index == -1
			const newAllMetadata = [...store.state.metadata]
			if (isNew) {
				newAllMetadata.push(newMetadata)
			} else {
				newAllMetadata[index] = newMetadata
			}
			store.setMetadata(newAllMetadata)
			dialogSo.dialogOpen({
				type: DIALOG_TYPE.INFO, modal: false,
				text: i18n.t(`snackbar.default.${isNew ? "new" : "save"}`)
			})
		},
		/**
		 * Elimina un METADATA
		 */
		async remove(metadata: Metadata, store?: MetadataStore) {
			if (!metadata) return
			const res = await dialogSo.dialogOpen({
				type: DIALOG_TYPE.WARNING,
				text: i18n.t("dialog.edit.delete-permanent"),
			})
			if (res == false) return
			store.setIsDialogOpen(false)

			await metaApi.remove(metadata)

			const index = store.getIndex({ serviceCode: metadata.serviceCode, name: metadata.name })
			if (index == -1) return
			const newAllMetadata = [...store.state.metadata]
			newAllMetadata.splice(index, 1)
			store.setMetadata(newAllMetadata)

			dialogSo.dialogOpen({
				type: DIALOG_TYPE.INFO, modal: false,
				text: i18n.t(`snackbar.default.delete`)
			})
			
			return true
		},
		/**
		 * Aggiorna i METADATA controlla se un metadata esiste gia' ed eventulmente lo crea
		 * [II] Al momento non è usato per questioni di performance
		 */
		update(
			{ serviceCode, values }: { serviceCode: string, values: Values },
			store?: MetadataStore
		) {
			if (!store.state.metadata) return null
			const newMetadata: Metadata[] = []
			Object.entries(values).forEach(([name, value]) => {
				const meta = store.getMetadata({ serviceCode, name })
				if (meta) return
				newMetadata.push({ serviceCode, name, _tmp: true })
			})
			if (newMetadata.length == 0) return
			store.setMetadata([...store.state.metadata, ...newMetadata])
		},
	},

	mutators: {
		setMetadata: (metadata: Metadata[]) => ({ metadata }),

		setInEdit: (inEdit: Metadata) => ({ inEdit }),
		setIsDialogOpen: (isDialogOpen: boolean) => ({ isDialogOpen }),
		setFormat: (format: MetaTypeFormat) => ({ format }),

		setGroups: (groups: string[]) => ({ groups }),
		setServices: (services: string[]) => ({ services }),
	},
}



export type MetadataActions = typeof setup.actions
export type MetadataMutators = typeof setup.mutators
export type MetadataState = typeof setup.state
export type MetadataGetters = typeof setup.getters
export interface MetadataStore extends StoreCore<MetadataState>, MetadataGetters, MetadataActions, MetadataMutators {
	state: MetadataState
}
const metaSo = createStore(setup) as MetadataStore
export default metaSo

