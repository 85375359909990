import { Settings as ServiceIcon } from "@mui/icons-material";
import React, { FunctionComponent, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import metaSo from "stores/metadata";
import SelectorDialogBase from "../base/SelectorDialogBase";
import Chip from "./Chip";



interface Props {
	/** id delle SEVERITY selezionata */
	service?: string,
	/** quando si seleziona una SEVERITY  */
	onChange: (service: string) => void,
	sx?: any
}

const SelectorMetaService: FunctionComponent<Props> = ({
	service,
	onChange,
	sx,
}) => {

	// STORE


	// HOOKs
	const { t } = useTranslation()
	const [isOpen, setIsOpen] = useState(false)

	// HANDLER
	const handleClick = () => {
		setIsOpen(true)
	}
	const handleClose = (service: string) => {
		setIsOpen(false)
		onChange?.(service)
	}
	const handleClear = (e: React.MouseEvent) => {
		e.stopPropagation()
		setIsOpen(false)
		onChange?.(null)
	}

	// RENDER
	const services = useMemo(() => [null, ...metaSo.state.services], [metaSo.state.services])
	const isActive = service != null

	return (<>
		<Chip sx={sx}
			label={service ?? "ALL SERVICES"}
			icon={<ServiceIcon />}
			tooltip={t("selector.service.tooltip")}
			active={isActive}
			onClick={handleClick}
			onClear={handleClear}
		/>
		<SelectorDialogBase
			width="auto"
			
			icon={<ServiceIcon />}
			items={services}
			idSelect={service}
			title={t("selector.service.title")}
			isOpen={isOpen}
			onClose={handleClose}
			onClear={handleClear}

			fnTextFromItem={item => item ?? "ALL SERVICES"}
			fnIdFromItem={item => item}
		/>
	</>)
}

export default SelectorMetaService
