import ajax from "plugins/AjaxService";
import { CHANNELS, Channel } from "plugins/ChannelService";
import layoutSo from "stores/layout";
import { Cycle } from "types/Cycle";
import { Layer } from "types/Layer";
import { DateRange, DateString, Uuid } from "types/global";
import { dateRangeToDateRangeString } from 'utils';


// url builder
const urlFarmBuilder = (farmId: number, cycleId?: number): string => `farms/${farmId}/cycles${cycleId ? `/${cycleId}` : ""}`
const urlGrowUnitBuilder = (growUnitId: number, cycleId?: number): string => `grow_units/${growUnitId}/cycles${cycleId ? `/${cycleId}` : ""}`

/**
 * INDEX (FARM)
 * https://app.clickup.com/30373227/v/dc/wyxbb-20075/wyxbb-28095
 */
function indexByFarm(farmId: number, range?: DateRange): Promise<{ data: Cycle[] }> {
    const url = urlFarmBuilder(farmId)
    const r = dateRangeToDateRangeString(range)
    const query = `start_date=${r.start}&end_date=${r.end}`
    return ajax.get(`${url}?${query}`)
}

/**
 * INDEX (GROW UNIT)
 * https://airilabs.myjetbrains.com/youtrack/articles/ZOS-A-222/index
 */
function indexByGrowUnit(growUnitId: number, range?: DateRange) {
    const url = urlGrowUnitBuilder(growUnitId)
    const r = dateRangeToDateRangeString(range)
    const query = `start_date=${r.start}&end_date=${r.end}`
    return ajax.get(`${url}?${query}`)
}

/**
 * SHOW
 * https://app.clickup.com/30373227/v/dc/wyxbb-20075/wyxbb-28115
 */
function get(farmId: number, cycleId: number): Promise<{ data: Cycle }> {
    const url = urlFarmBuilder(farmId, cycleId)
    return ajax.get(url)
}

/**
 * https://app.clickup.com/30373227/v/dc/wyxbb-20075/wyxbb-25595
 */
function harvestCropLot(lotCode: string, yieldAmount: number, unitsYield: number) {
    return ajax.post(`cycles/yield_by_lot_code`, { lotCode, yieldAmount, unitsYield })
}

/**
 * https://airilabs.myjetbrains.com/youtrack/articles/ZOS-A-303/delay
 */
async function modifyByFarm(farmId: number, data: { cycles: Partial<Cycle>[] }): Promise<{ data: Cycle[] }> {
    const result = await ajax.post(`${urlFarmBuilder(farmId)}/delay`, data)
    return result
}

//#region DRAFT

/**
 * CREATE
 * https://app.clickup.com/30373227/v/dc/wyxbb-20075/wyxbb-28275
 */
async function defineDraft(cycle: DefineDataDraft, draftId: number, draftUuid: string): Promise<{ data: Cycle }> {
    return wrapChannelConfirm<{ data: Cycle }>(
        CHANNELS.CYCLETASKS,
        draftUuid,
        () => ajax.post(`cycles/cycles_drafts/${draftId}/define`, { cycle })
    )
}
export interface DefineDataDraft {
    growUnitId: number
    cropCycleId: number
    seedingDate: DateString
    phaseLayers: Partial<Layer>[]
}

/**
 * INDEX
 * https://app.clickup.com/30373227/v/dc/wyxbb-20075/wyxbb-28315
 */
async function indexDraft(draftId: number, range?: DateRange): Promise<{ data: Cycle[] }> {
    const url = `cycles/cycles_drafts/by_draft/${draftId}`
    const r = dateRangeToDateRangeString(range)
    const query = `start_date=${r.start}&end_date=${r.end}`
    return await ajax.get(`${url}?${query}`)
}

/**
 * BULK EDIT
 * https://app.clickup.com/30373227/v/dc/wyxbb-20075/wyxbb-29275
 */
async function bulkScheduleDraft(data: CycleDraftData, draftId: number, draftUuid: string): Promise<{ data: Cycle[] }> {
    if (!data) return
    if (data.rejected?.length == 0) {
        return ajax.post(`cycles/cycles_drafts/${draftId}/bulk_schedule`, data)
    }
    return wrapChannelConfirm<{ data: Cycle[] }>(
        CHANNELS.CYCLETASKS,
        draftUuid,
        () => ajax.post(`cycles/cycles_drafts/${draftId}/bulk_schedule`, data)
    )
}
export type CycleDraftData = {
    scheduled?: Cycle[],
    rejected?: { refToCycleUuid: Uuid }[],
    undo?: { refToCycleUuid: Uuid }[],
}


async function wrapChannelConfirm<T = unknown>(topic: string, draftUuid: string, apiCall: () => Promise<T>): Promise<T> {
    const channel = new Channel(topic, { draft_uuid: draftUuid });
    layoutSo.setLoadingMsg("")

    const cleanupChannel = (handler: (message: any) => void, timeoutId: ReturnType<typeof setTimeout>) => {
        clearTimeout(timeoutId);
        channel.unsubscribe(handler);
    }
    let resolveCable: () => void
    const promiseCable = new Promise<void>((resolve) => resolveCable = resolve);
    const handleRequestChannel = (message: { eventName?: string }) => {
        if (message?.eventName === "cycle_tasks_drafts_generated") {
            cleanupChannel(handleRequestChannel, timeoutId);
            resolveCable();
        }
    }
    await channel.subscribe(handleRequestChannel)

    const timeoutId = setTimeout(() => {
        cleanupChannel(handleRequestChannel, timeoutId);
        resolveCable();
    }, 5000)

    const [apiCallResult] = await Promise.all([apiCall(), promiseCable])

    layoutSo.setLoadingMsg(null)
    return apiCallResult;
}

const api = {
    indexByFarm,
    indexByGrowUnit, // USATO IN LAYERS/TOWERS
    get,
    modifyByFarm, // DA ELIMINARE
    harvestCropLot,

    //#region DRAFT
    defineDraft,
    indexDraft,
    bulkScheduleDraft,
    //#endregion
}

export default api
