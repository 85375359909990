import { createStore, StoreCore } from "@priolo/jon"
import theme from "styles/theme"
import { delay } from "utils"
import { MenuItem } from "./types"
import { PAGE_ID } from "stores/route/utils/types"



const isPLC = import.meta.env.VITE_TARGET == "plc"

/**
 * le VOCI del menu in alto per tornare indietro
 */
/**
 * Gestione Main Menu laterale 
 * la parte superiore dei PARENTs
 * - animazioni
 * - contenuti
 */
const setup = {

	state: {
		overwritePage: <PAGE_ID>null,
		items: <MenuItem[]>[],
		height: 0,
	},

	getters: {
	},

	actions: {
		/** aggiorno il contenuto degli item parents */
		update: (items: MenuItem[], store?: ParentsStore) => {
			const anim = async () => {
				const duration = theme.transitions.duration.standard

				// se sono in zero-os tolgo la root perche' non la visualizzo nel menu
				const itemsNum = items.length - (!isPLC ? 1 : 0)
				const height = itemsNum * theme.app.menu.parent.height

				// se è un nodo root (LIBRARY, FARMS ...)
				//if ([PAGE_ID.FARMS, PAGE_ID.LIBRARY, PAGE_ID.SETTINGS].includes(items[0].id)) {
				//	store.setItems(items)
				// se è uguale al precedente non fare l'animazione
				//} else if (items.length == store.state.items.length) {
				if (items.length == store.state.items.length) {
					store.setItems(items)
				} else if (items.length > store.state.items.length) {
					store.setItems(items)
					store.setHeight(height)
				} else {
					store.setHeight(height)
					await delay(duration)
					store.setItems(items)
				}
			}
			anim()
		},
	},

	mutators: {
		setOverwritePage: (overwritePage: PAGE_ID) => ({ overwritePage }),
		setItems: (items: MenuItem[]) => ({ items }),
		setHeight: (height: number) => ({ height }),
	},
}

export type ParentsState = typeof setup.state
export type ParentsGetters = typeof setup.getters
export type ParentsActions = typeof setup.actions
export type ParentsMutators = typeof setup.mutators
export interface ParentsStore extends StoreCore<ParentsState>, ParentsGetters, ParentsActions, ParentsMutators {
	state: ParentsState
}
const parentsSo = createStore(setup) as ParentsStore
export default parentsSo
