import { PAGE_ID } from "stores/route/utils/types"
import { MenuItem } from "./types"
import i18n from "i18next"
import { COMPONENT_TYPE } from "types/Service"
import farmSo from "stores/farm"



/**
 * sottosezione FARM -> COMPONENTS
 */
const ITEMS_GROWUNIT: MenuItem[] = [
	// MAIN
	{ id: PAGE_ID.GROWUNIT_HOME, title: "MAIN", group: COMPONENT_TYPE.GROWUNIT, onlyNats: true },
	// PLANT
	{ id: PAGE_ID.GROWUNIT_MAIN, title: "PLANT", group: COMPONENT_TYPE.GROWUNIT, onlyNats: true },
	// LAYERS
	{ id: PAGE_ID.GROWUNIT_LAYERS, title: "LAYERS", group: COMPONENT_TYPE.GROWUNIT, divider: true },
	// ACTIONS	
	{ id: PAGE_ID.GROWUNIT_FERTI, title: "ACTIONS", group: COMPONENT_TYPE.GROWUNIT, onlyNats: true },
	// ADJ
	{ id: PAGE_ID.GROWUNIT_ADJ, title: "ADJUSTMENTS", group: COMPONENT_TYPE.GROWUNIT, onlyNats: true },
	// LIGHTING
	{ id: PAGE_ID.GROWUNIT_LIGHTING, title: "LIGHTING", group: COMPONENT_TYPE.GROWUNIT, onlyNats: true },
	// LIGHTING_PS
	{ id: PAGE_ID.GROWUNIT_LIGHTING_PS, title: "LIGHTING P.S.", group: COMPONENT_TYPE.GROWUNIT, onlyNats: true },
	// IRRIGATION
	{ id: PAGE_ID.GROWUNIT_IRRIGATION, title: "IRRIGATION", group: COMPONENT_TYPE.GROWUNIT, onlyNats: true, divider: true },
	// ALERTS
	{ id: PAGE_ID.GROWUNIT_PLC_ALERTS, title: "ALERTS", group: COMPONENT_TYPE.GROWUNIT, onlyNats: true },
	// RECIPE
	{ id: PAGE_ID.GROWUNIT_RECIPE, title: "RECIPE", group: COMPONENT_TYPE.GROWUNIT, },

	{ id: PAGE_ID.COMPONENT_HVAC, title: "MAIN", group: COMPONENT_TYPE.HVAC, onlyNats: true },
	{ id: PAGE_ID.COMPONENT_HVAC_ALERTS, title: "PLC WARNING", group: COMPONENT_TYPE.HVAC, onlyNats: true },

	{ id: PAGE_ID.COMPONENT_GERMINATION, title: "MAIN", group: COMPONENT_TYPE.GERMINATOR, onlyNats: true },
	{ id: PAGE_ID.COMPONENT_GERMINATION_ALERTS, title: "PLC WARNING", group: COMPONENT_TYPE.GERMINATOR, onlyNats: true },

	{ id: PAGE_ID.COMPONENT_OSMOTIC_WATER, title: "MAIN", group: COMPONENT_TYPE.OSMOTIC_WATER, onlyNats: true },
	{ id: PAGE_ID.COMPONENT_OSMOTIC_WATER_ALERTS, title: "PLC WARNING", group: COMPONENT_TYPE.OSMOTIC_WATER, onlyNats: true },

	{ id: PAGE_ID.FARM_CO2, title: "MAIN", group: COMPONENT_TYPE.CO2, onlyNats: true },
]

/**
 * macrosezione FARM
 */
const ITEMS_FARM: MenuItem[] = [
	{
		id: PAGE_ID.FARM_MAP, title: "HOME",
		isHidden: () => !farmSo.state.select?.mapConfig,
		onlyNats: true
	},
	{ id: PAGE_ID.FARM_GROWUNITS, title: i18n.t("menu.farms.growUnits.label"), children: ITEMS_GROWUNIT, divider: true },

	{ id: PAGE_ID.FARM_PLANNER, title: i18n.t("menu.farms.planner") },
	{ id: PAGE_ID.FARM_AI_PLANNING, title: i18n.t("menu.farms.productions"), onlyDebug: true },
	{ id: PAGE_ID.FARM_TASKS, title: i18n.t("menu.farms.tasks") },
	{ id: PAGE_ID.FARM_CYCLES, title: i18n.t("menu.farms.cycles") },
	{ id: PAGE_ID.FARM_ALERTS, title: i18n.t("menu.farms.alerts"), divider: true },

	{ id: PAGE_ID.FARM_PLC_ALERTS, title: "PLC WARNING", onlyNats: true },
	{
		id: PAGE_ID.COMPONENT_POWER_MONITOR, title: "POWERS", onlyNats: true, divider: true,
		isHidden: () => farmSo.getComponentsByType(COMPONENT_TYPE.POWERS).length == 0
	},
	{ id: PAGE_ID.FARM_METADATA, title: i18n.t("menu.metadata.label"), onlyNats: true },

	{ id: PAGE_ID.FARM_BI, title: "YIELDS", onlyDebug: true },
	// { title: "menu.farms.charts", id: PAGE_ID.FARM_CHARTS },
	// { title: "menu.farms.consoles", id: PAGE_ID.FARM_CONSOLES },
	{ id: PAGE_ID.FARM_TECHNICAL_DASHBOARDS, title: i18n.t("menu.farms.technicalDashboards") },
	{ id: PAGE_ID.FARM_BUSINESS_DASHBOARDS, title: i18n.t("menu.farms.businessDashboards") },
]

/**
 * macrosezione LIBRARY
 */
const ITEMS_LIBRARY: MenuItem[] = [
	// { id: PAGE_ID.FARMS, title: i18n.t("menu.farms.label"), children: ITEMS_FARM },
	{ id: PAGE_ID.RECIPES, title: i18n.t("menu.recipes"), subIds: [PAGE_ID.RECIPES_DETAIL] },
	{ id: PAGE_ID.CROPS, title: i18n.t("menu.crops"), subIds: [PAGE_ID.CROPS_DETAIL] },
	{ id: PAGE_ID.PRODUCTS, title: i18n.t("menu.products"), subIds: [PAGE_ID.PRODUCTS_DETAIL], onlyAdmin: true },
	// { id: PAGE_ID.ALARMS, title: i18n.t("menu.alarms"), onlyAdmin: true, subIds: [PAGE_ID.ALARMS_DETAIL] },
	// { id: PAGE_ID.ROOT, title: i18n.t("menu.root") },
	// { id: PAGE_ID.ZW, title: i18n.t("menu.zw") },
]

/**
 * macrosezione LIBRARY
 */
const ITEMS_SETTINGS: MenuItem[] = [
	{ id: PAGE_ID.ALARMS, title: i18n.t("menu.alarms"), onlyAdmin: true, subIds: [PAGE_ID.ALARMS_DETAIL] },
]

/**
 * ROOT del MAIN-MENU
 */
export const ITEMS_MAIN: MenuItem[] = [
	{ id: PAGE_ID.LIBRARY, title: "LIBRARY", children: ITEMS_LIBRARY },
	{ id: PAGE_ID.FARMS, title: i18n.t("menu.farms.label"), children: ITEMS_FARM },
	{ id: PAGE_ID.SETTINGS, title: "SETTINGS", children: ITEMS_SETTINGS },
]