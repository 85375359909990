
/**
 * https://app.clickup.com/30373227/v/dc/wyxbb-16070/wyxbb-16110
 */
export interface Metadata {
	/** nome della CODA */
	serviceCode?: string
	/** key del VALUE */
	name?: string

	type?: META_TYPE
	format?: string
	alias?: string

	description?: string
	target?: string
	group?: string
	readOnly?:boolean			
	/** indica se il dato deve essere "graficato" */
	graph?: boolean
	/** descrizione */
	tag?: string
	/** unità di misura */
	um?: string
	/** valore minimo */
	min?: number
	/** valore massimo */
	max?: number
	/** indica se il dato è un parametro gestito sul plan di little root */
	recipe?: boolean
	/** indica che il metadata è temporaneo */
	_tmp?: boolean
}

export function isEqual(m1: Metadata, m2: Metadata): boolean {
	return m1.serviceCode == m2.serviceCode && m1.name == m2.name
}

export enum META_TYPE {
	BOOLEAN = "boolean",
	INTEGER = "integer",
	REAL = "real",
}
