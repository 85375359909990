import dayjs from "dayjs";
import { Incident } from "types/Incident";



export function getIncidentsFiltered(incidents: Incident[], cycleId?: number): Incident[] {
	if ( incidents == null ) return []
	return incidents.filter(incident => {
		const cycleCheck = !cycleId || incident.cycleId == cycleId
		return cycleCheck
	})
}

export function getNewIncident(cycleId: number): Incident {
	return {
		id: null,
		cycleId,
		incidentType: null,
		dateOfOccurrence: dayjs().format("YYYY-MM-DD"),
		resolved: false,
		reverted: false,
		notes: "",
		//totalLostTrolleys: 0,
		lostTrolleysPerCropLot: [{ cropLotId: null, numberOfLostTrolleys: 1 }],
		positionOfTrolleys: [],
	}
}