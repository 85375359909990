import ajax from "plugins/AjaxService"
import { NATS_META_REQUESTS, NATSRequest } from "plugins/nats"
import farmSo from "stores/farm"
import { Metadata } from "types/Metadata"

// https://app.clickup.com/30373227/v/dc/wyxbb-20075/wyxbb-16110

/**
 * Recupera tutti i METADATA
 * https://app.clickup.com/30373227/v/dc/wyxbb-20075/wyxbb-16130
 */
function index(baseUrl?: string): Promise<{ data: Metadata[] }> {
	if (farmSo.isInCloud()) {
		return NATSRequest(NATS_META_REQUESTS.INDEX)
	}
	return ajax.get(`metadata`, null, { baseUrl, isEdge: true })
}

/**
 * Rimuove un METADATA 
 * https://app.clickup.com/30373227/v/dc/wyxbb-20075/wyxbb-16150
 */
function remove(meta: Metadata): Promise<void> {
	const data = {
		serviceCode: meta.serviceCode,
		name: meta.name,
	}
	if (farmSo.isInCloud()) {
		return NATSRequest(NATS_META_REQUESTS.REMOVE, data)
	}
	const baseUrl = farmSo.state.select?.edgeServicesConfig?.host?.url
	return ajax.delete(`metadata`, data, { baseUrl, isEdge: true })
}

/**
 * Modifica/crea un METADATA
 * https://app.clickup.com/30373227/v/dc/wyxbb-20075/wyxbb-16170
 */
function save(meta: Metadata): Promise<{ data: Metadata }> {
	if (farmSo.isInCloud()) {
		return NATSRequest(NATS_META_REQUESTS.SAVE, meta)
	}
	const baseUrl = farmSo.state.select?.edgeServicesConfig?.host?.url
	return ajax.post(`metadata`, meta, { baseUrl, isEdge: true })
}

const api = {
	index,
	remove,
	save,
}

export default api