import { META_TYPE, Metadata } from "types/Metadata"


//#region FORMAT

/** 
 * restituisce la string della proprietà "format" dall'oggetto MetaTypeFormat 
 * */
export function formatToMeta(format: MetaTypeFormat): string {
	if (format.labelOn && format.labelOff) {
		return `${format.labelOn}/${format.labelOff}`
	}
	return formatNumber(format)
}
function formatNumber(format: MetaTypeFormat): string {
	let strFormat = ""
	if (format.decimals == null || isNaN(format.decimals)) return ""
	if (format.decimals == 0) {
		strFormat = "0"
	} else {
		strFormat = `0.${"0".repeat(format.decimals)}`
	}
	if (format.thousands) strFormat = `#,##${strFormat}`
	return strFormat
}


/** 
 * restituisce l'oggetto MetaTypeFormat da un oggetto METADATA 
 * */
export function formatFromMeta(meta: Metadata): MetaTypeFormat {
	let format: MetaTypeFormat = {}
	if (!meta) return format
	if (meta.type == META_TYPE.BOOLEAN) {
		const [lOn, lOff] = meta.format?.split("/") ?? []
		if (lOn) format.labelOn = lOn
		if (lOff) format.labelOff = lOff
		return format
	}

	if (meta.type == META_TYPE.INTEGER) {
		format.decimals = 0
	} else if (meta.type == META_TYPE.REAL) {
		const dec = meta.format.split(".")[1]?.length ?? null
		format.decimals = dec
	}
	format.thousands = meta.format?.startsWith("#,##")
	return format
}

/** 
 * dato un VALUE e un METADATA restituisce la stringa da visualizzare 
 * */
export function formatValue(value: any, meta: Metadata): string {
	if (value == null) return null
	if (!meta) return value
	const format = formatFromMeta(meta)
	switch (meta.type) {
		case META_TYPE.BOOLEAN:
			return value ? (format.labelOn ?? "ON") : (format.labelOff ?? "OFF")
		case META_TYPE.INTEGER:
			return Number.parseInt(value).toString()
		case META_TYPE.REAL:
			if (format.decimals == null) return value
			return Number.parseFloat(value).toFixed(format.decimals)
		default:
			return value
	}
}

/** 
 * utilizzato per esporre i dati del METADATA utili alla visualizzazione 
 * */
export interface MetaTypeFormat {
	labelOn?: string
	labelOff?: string
	decimals?: number
	thousands?: boolean
}

//#endregion




/** dato un array di METADATA li filtra in base al contentuto dei parametri a seguire */
// export function filter(metadata: Metadata[], text: string, group:string, service:string): Metadata[] {
// 	if (!metadata) return []
// 	text = text?.trim().toLowerCase()
// 	if (!text || text.length <= 3) return metadata
// 	const words = text.split(" ").filter ( w => w.length > 3 )
// 	return metadata.filter(meta => {
// 		if (words.every(word =>
// 			meta.serviceCode?.toLowerCase().includes(word)
// 			|| meta.name?.toLowerCase().includes(word)
// 			|| meta.group?.toLowerCase().includes(word)
// 			|| meta.target?.toLowerCase().includes(word)
// 		)) return true
// 		if (meta.description?.toLowerCase().includes(text)) return true
// 		return false
// 	})
// }

export enum METADATA_FILTER_PROP {
	ALIAS_MODIFIED = "ALIAS MODIFIED",
	GRAPHED = "GRAPHED",
}

/** dato un array di METADATA li filtra in base al contentuto dei parametri a seguire */
export function filter(metadata: Metadata[], text?: string, group?: string, service?: string, feature?: string): Metadata[] {
	if (!metadata) return []
	text = text?.trim().toLowerCase()
	if (text?.length < 3) text = null

	//const searchWords = text?.trim().toLowerCase().split(/\s+/).filter(word => word.length >= 3) ?? [];

	const searchWords = text
		? text.trim().toLowerCase().match(/\"[^\"]+\"|\S+/g)?.map(word => {
			word = word.replace(/^"|"$/g, '');
			return word.length >= 3 ? word : null;
		}).filter(word => word !== null)
		: [];

	return metadata.filter(meta => {
		if (group && meta.group != group) return false
		if (service && meta.serviceCode != service) return false
		if (!!feature) {
			if (feature == METADATA_FILTER_PROP.ALIAS_MODIFIED && meta.name == meta.alias) return false
			if (feature == METADATA_FILTER_PROP.GRAPHED && !meta.graph) return false
		}
		if (searchWords.length > 0) {
			const searchableFields = [
				meta.name,
				meta.alias,
				meta.description,
				meta.target
			].map(field => field.toLowerCase());

			return searchWords.every(word =>
				searchableFields.some(field => field.includes(word))
			);
		}
		// if (text 
		// 	&& !meta.name?.toLowerCase().includes(text)
		// 	&& !meta.alias?.toLowerCase().includes(text)
		// 	&& !meta.description?.toLowerCase().includes(text)
		// 	&& !meta.target?.toLowerCase().includes(text)
		// ) return false
		return true
	})
}

/** descrizione del TYPE di METADATA */
export function descRole(meta: Metadata): string {
	if (meta.type == META_TYPE.BOOLEAN) {
		return meta.format ?? "on/off"
	}
	return `min: ${meta.min ?? "--"} max: ${meta.max ?? "--"}`
}

/** prelevo una stringa identificativa univoca del METADATE */
export function getId(meta: Metadata): string {
	if (!meta) return null
	return `${meta.serviceCode}${meta.name}`
}

/** raggruppa un array in blocchi della dimensione dim */
export function groupBlock<T>(array: T[], dim: number): T[][] {
	if (!array) return null
	return array.reduce((acc, item) => {
		const ultimoBlocco = acc[acc.length - 1];
		if (ultimoBlocco && ultimoBlocco.length < dim) {
			ultimoBlocco.push(item);
		} else {
			acc.push([item]);
		}
		return acc
	}, [])
}