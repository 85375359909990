import { Card, CircularProgress, Modal, Typography } from "@mui/material";
import { useStore } from "@priolo/jon";
import { FunctionComponent } from 'react';
import layoutSo from "stores/layout";


const LoadingCmp: FunctionComponent = () => {

	// HOOKs
	const layoutSa = useStore(layoutSo)

	// HANDLER

	// RENDER
	const isOpen = layoutSa.loadingMsg != null
	const message = layoutSa.loadingMsg?.length > 0 ? layoutSa.loadingMsg : "Please wait ..."

	return (
		<Modal open={isOpen} >

			<Card sx={cssCard} elevation={0}>
				<CircularProgress />
				<Typography sx={{ mt: 3, textAlign: "center" }}>
					{message}
				</Typography>
			</Card>

		</Modal>
	)
}

export default LoadingCmp

const cssCard = {
	display: "flex", flexDirection: "column", alignItems: "center",
	position: 'absolute' as 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -80%)',
	width: 200,
	p: 4,
	outline: 0,
}
